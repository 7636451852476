import { Component } from '@angular/core';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent {

  title = 'My first AGM project';
  lat = 52.209367;
  lng = 13.231187;
  zoom = 9;

}
