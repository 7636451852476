import { Component, OnInit } from '@angular/core';

export interface Elements {
  bez: string;
  besch: string;
  bem: string;
 
}

const ELEMENT_DATA: Elements[] = [
  {bez: 'AP-F S 800, KL. A', besch: 'exentrische Öffnung, verschiebesicher, 110Kg', bem: 'mit Deckel'},
  {bez: 'AP-F S 1000, KL. A', besch: 'exentrische Öffnung, verschiebesicher, 215Kg', bem: 'mit Deckel'},
  {bez: 'AP-F S 1000, KL. B', besch: 'exentrische Öffnung, 625mm, 370Kg', bem: 'ohne Deckel'},
  {bez: 'AP-F S 1000, KL. D', besch: 'exentrische Öffnung, 625mm, 380Kg', bem: 'ohne Deckel'},
  {bez: 'AP-F S 1500, KL. A', besch: 'exentrische Öffnung, 590Kg', bem: 'ohne Deckel'},
  {bez: 'AP-F S 1500, KL. B', besch: 'exentrische Öffnung, 925Kg', bem: 'ohne Deckel'},
  {bez: 'AP-F S 1500, KL. C', besch: 'exentrische Öffnung, 950Kg', bem: 'ohne Deckel'},

  {bez: 'AP-F S 2000, KL. A', besch: 'exentrische Öffnung, 625mm, 1,1t', bem: 'ohne Deckel'},
  {bez: 'AP-F S 2000, KL. B', besch: 'exentrische Öffnung, 625mm, 1,5t', bem: 'ohne Deckel'},
  {bez: 'AP-F S 2000, KL. D', besch: 'exentrische Öffnung, 1000mm, 1,5t', bem: 'ohne Deckel'},

  {bez: 'Kranzdeckel mit Rahmen', besch: 'Durchmesser 800 mm, Höhe 10 cm, 50 Kg gesamt ( 30/20 Kg )', bem: 'begehbar'},
  {bez: 'Göbeldeckel', besch: 'Durchmesser 785 mm, Höhe 8 cm, 80 Kg', bem: '5t befahrbar'},

  {bez: 'Für DN 600 Schacht', besch: 'Durchmesser: 720 mm, 55 Kg', bem: 'begehbar'},
  {bez: 'Für Konus', besch: 'Durchmesser 805 mm , Höhe 6 cm, 60 Kg', bem: 'begehbar'},
  {bez: 'Durchmesser 1200 ( 1000 Schacht )', besch: '1 Tlg. Abdeckung, H=8cm, 215 Kg', bem: 'begehbar'},
  {bez: 'Durchmesser 1200 ( 1000 Schacht )', besch: '2 Tlg. Abdeckung,  H=7cm, 200 Kg', bem: 'begehbar'},
  {bez: 'Durchmesser 1000 ( 800 Schacht )', besch: '1 Tlg. Abdeckung,   H=6 cm, 100 Kg', bem: 'begehbar'},
  {bez: 'Durchmesser 1000 ( 800 Schacht )', besch: '2 Tlg. Abdeckung,   H=6 cm, 100 Kg', bem: 'begehbar'},

  {bez: 'Betonguss Kl. B 125 ( PKW ), 96 Kg', besch: 'mit Lüftung', bem: 'befahrbar'},
  {bez: 'Betonguss Kl. B 125 ( PKW ), 98 Kg', besch: 'ohne Lüftung', bem: 'befahrbar'},
  {bez: 'Betonguss Kl. D 400 ( LKW ), 191 Kg', besch: 'mit Lüftung', bem: 'befahrbar'},
  {bez: 'Betonguss Kl. D 400 ( LKW ), 193 Kg', besch: 'ohne Lüftung', bem: 'befahrbar'},





];

@Component({
  selector: 'app-abdeckung',
  templateUrl: './abdeckung.component.html',
  styleUrls: ['./abdeckung.component.css']
})
export class AbdeckungComponent {

  displayedColumnsabdeckung: string[] = ['bez','besch','bem'];
  dataSource = ELEMENT_DATA;

}
