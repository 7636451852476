import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';
import {MatTableModule} from '@angular/material/table';
import {CdkTableModule} from '@angular/cdk/table';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { ComponentsComponent } from './components.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalBasic } from './modal/modal.component';
import { TableBasicExample } from './product-table/product-table.component';
import { FertigbetonComponent } from './products/fertigbeton/fertigbeton.component';
import { TiefbauComponent } from './products/tiefbau/tiefbau.component';
import { SchachtringComponent } from './products/schachtring/schachtring.component';
import { MapsComponent } from './maps/maps.component';
import { AgmCoreModule } from '@agm/core';
import { WasserschatComponent } from './products/wasserschat/wasserschat.component';
import { AbdeckungComponent } from './products/abdeckung/abdeckung.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAYgTg_pIyD92x2hg3FE4SdzD8STWW2D_s'
          })
      ],
    declarations: [
        ComponentsComponent,
        BasicelementsComponent,
        NavigationComponent,
        TypographyComponent,
        NucleoiconsComponent,
        NotificationComponent,
        NgbdModalBasic,
        TableBasicExample,
        FertigbetonComponent,
        TiefbauComponent,
        SchachtringComponent,
        MapsComponent,
        WasserschatComponent,
        AbdeckungComponent,
        
    ],
    exports:[ ComponentsComponent,MatTableModule,CdkTableModule ]
})
export class ComponentsModule { }
