import { Component, OnInit } from '@angular/core';

export interface Elements {
  bez: string;
  din: string;
  bem: string;
 
}

const ELEMENT_DATA: Elements[] = [
  {bez: 'Schachtring SR-M 1000x250mm', din: 'DIN 4034 / Teil 1', bem: ''},
  {bez: 'Schachtring SR-M 1000x250mm', din: 'DIN 4034 / Teil 1', bem: 'Mit Steigeisen'},
  {bez: 'Schachtring SR-M 1000x500mm', din: 'DIN 4034 / Teil 1', bem: ''},
  {bez: 'Schachtring SR-M 1000x500mm', din: 'DIN 4034 / Teil 1', bem: 'Mit Steigeisen'},
  {bez: 'Schachtring SR-M 1000x750mm', din: 'DIN 4034 / Teil 1', bem: ''},

  {bez: 'Schachtring SR-F 600x250mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung'},
  {bez: 'Schachtring SR-F 600x500mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung'},
  {bez: 'Schachtring SR-F 800x250mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung'},
  {bez: 'Schachtring SR-F 800x250mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen - Auf Bestellung '},
  {bez: 'Schachtring SR-F 800x500mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung '},
  {bez: 'Schachtring SR-F 800x500mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen - Auf Bestellung '},
  {bez: 'Schachtring SR-F 800x500mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung '},
  {bez: 'Schachtring SR-F 800x500mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen - Auf Bestellung '},


  {bez: 'Schachtring SR-F 1000x250mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Schachtring SR-F 1000x250mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen'},
  {bez: 'Schachtring SR-F 1000x500mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Schachtring SR-F 1000x500mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen'},
  {bez: 'Schachtring SR-F 1500x250mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Schachtring SR-F 1500x250mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen'},
  {bez: 'Schachtring SR-F 1500x500mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Schachtring SR-F 1500x500mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen'},
  {bez: 'Schachtring SR-F 2000x500mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Schachtring SR-F 2000x750mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung'},
  {bez: 'Schachtring SR-F 2500x500mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung'},



  {bez: 'Sickerring SR-F 1000x250mm', din: 'DIN 4034 / Teil 2', bem: 'Mit Steigeisen'},
  {bez: 'Sickerring SR-FS 1500x250mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Sickerring SR-FS 1500x500mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Sickerring SR-F 2000x500mm', din: 'DIN 4034 / Teil 2', bem: ''},
  {bez: 'Sickerring SR-F 2000x750mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung'},
  {bez: 'Sickerring SR-F 2500x500mm', din: 'DIN 4034 / Teil 2', bem: 'Auf Bestellung'},





  {bez: 'Schachthals SH-F 800x625x600mm', din: ' DIN 4034 / Teil 2', bem: 'Auf Bestellung'}, 
  {bez: 'Schachthals SH-F 800x625x600mm', din: ' DIN 4034 / Teil 2', bem: 'Mit Steigeisen - Auf Bestellung'}, 
  {bez: 'Schachthals SH-M 1000/625x300mm', din: 'DIN V 4034', bem: 'Mit Steigeisen'},
  {bez: 'Schachthals SH-M 1000x625x600mm', din: 'DIN V 4034', bem: ''},
  {bez: 'Schachthals SH-M 1000x625x600mm', din: 'DIN V 4034', bem: 'Mit Steigeisen'},
  {bez: 'Schachthals SH-F 1500x625x600mm', din: ' DIN 4034 / Teil 2', bem: 'Exzentrisch Kl.B.'}, 
  {bez: 'Schachthals SH-F 1500x625x600mm', din: ' DIN 4034 / Teil 2', bem: 'Mit Steigeisen - Kl. B.'}, 
  {bez: 'Schachthals SH-F 2000x625x600mm', din: ' DIN 4034 / Teil 2', bem: 'Mit Steigeisen - Kl. B.'}, 
  {bez: 'Schachthals SH-F 2500x625x600mm', din: ' DIN 4034 / Teil 2', bem: 'Zentrisch - Auf Bestellung'}, 
  
  {bez: 'Betonboden SR-FB 800x500mm', din: ' DIN 4034 / Teil 2', bem: 'Auf Bestellung'}, 
  {bez: 'Betonboden SR-FB 1500x500mm', din: ' DIN 4034 / Teil 2', bem: ''}, 
  {bez: 'Betonboden SR-FB 2000x800mm', din: ' DIN 4034 / Teil 2', bem: ''}, 
  {bez: 'Betonboden SR-FB 2500x780mm', din: ' DIN 4034 / Teil 2', bem: 'Auf Bestellung'}, 
  {bez: 'Minikonus 800x625x300mm', din: ' DIN 4034 / Teil 2', bem: 'Auf Bestellung'}, 
  {bez: 'Minikonus 800x625x300mm', din: ' DIN 4034 / Teil 2', bem: 'Mit Steigeisen - Auf Bestellung'}, 




  {bez: 'Dichtung Forsheda F 114 vorgeschmiert', din: '', bem: ''},




];

@Component({
  selector: 'app-schachtring',
  templateUrl: './schachtring.component.html',
  styleUrls: ['./schachtring.component.css']
})
export class SchachtringComponent {

  displayedColumnsschachtring: string[] = ['bez','din','bem'];
  dataSource = ELEMENT_DATA;

}
