import { Component, OnInit } from '@angular/core';



export interface Elements {
  bez: string;
  durch: string;
  bem: string;
 
}

const ELEMENT_DATA: Elements[] = [
  {bez: 'DIN 4034-1 Wasserzählschacht DN 1000 Rund', durch: '10000mm', bem: 'begehbar'},
  {bez: 'DIN 4034-1 Wasserzählschacht DN 1000 Rund', durch: '10000mm', bem: '12,5t befahrbar'},
  {bez: 'DIN 4034-1 Wasserzählschacht DN 1000 Rund', durch: '10000mm', bem: '40t   befahrbar'},

  {bez: 'Wasserzählschacht DN 40 Vierecking', durch: '1000x12000mm', bem: '1,5t  begehbar'},
  {bez: 'Wasserzählschacht DN 40 Vierecking', durch: '1000x12000mm', bem: '12,5t befahrbar'},
  {bez: 'Wasserzählschacht DN 40 Vierecking', durch: '1000x12000mm', bem: '40t   befahrbar'},


  {bez: 'Wasserzählschacht DN 50 Vierecking', durch: '1500x15000mm', bem: '1,5t  begehbar - Auf Bestellung'},
  {bez: 'Wasserzählschacht DN 50 Vierecking', durch: '1500x15000mm', bem: '12,5t befahrbar - Auf Bestellung'},  
  {bez: 'Wasserzählschacht DN 50 Vierecking', durch: '1500x15000mm', bem: '40t befahrbar - Auf Bestellung'},

  {bez: 'Wasserzählschacht DN 100 Vierecking', durch: '1600x2500mm', bem: '1,5t  begehbar - Auf Bestellung'},
  {bez: 'Wasserzählschacht DN 100 Vierecking', durch: '1600x2500mm', bem: '12,5t  begehbar - Auf Bestellung'},
  {bez: 'Wasserzählschacht DN 100 Vierecking', durch: '1600x2500mm', bem: '40t  begehbar - Auf Bestellung'},

];


@Component({
  selector: 'app-wasserschat',
  templateUrl: './wasserschat.component.html',
  styleUrls: ['./wasserschat.component.css']
})
export class WasserschatComponent  { 

  displayedColumnswasser: string[] = ['bez','durch','bem'];
  dataSource = ELEMENT_DATA;

}
