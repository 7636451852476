import { Component, OnInit } from '@angular/core';

export interface Elements {
  bezeichnung: string;
  menge: string;
  preis: string;
 
}

const ELEMENT_DATA: Elements[] = [
  {bezeichnung: 'Verzögerer', menge: '1 Kg', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 8/10  Körnung 0-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 8/10  Körnung 8-16', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 12/15 Körnung 0-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 12/15 Körnung 0-16', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 16/20 Körnung 0-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 16/20 Körnung 0-16', menge: '1 m³', preis: 'Tagespreis'},

  {bezeichnung: 'Transportbeton C 20/25 Körnung 0-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 20/25 Körnung 0-16', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 20/25 Körnung 0-8  F3', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 20/25 Körnung 0-16 F3', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 25/30 Körnung 0-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 25/30 Körnung 0-16', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 25/30 Körnung 0-8  F3', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 25/30 Körnung 0-16 F3', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 30/37 Körnung 0-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 30/37 Körnung 0-16', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 30/37 Körnung 0-8  F3', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 30/37 Körnung 0-16 F3', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Transportbeton C 35/45 Körnung 0-8  F3', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Pflasterbeton C 12/15 Körnung 0-2 Rz. 250', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Pflasterbeton C 16/20 Körnung 0-2 Rz. 300', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Pflasterbeton C 20/25 Körnung 0-2 Rz. 350', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Drainbeton  Körnung 2-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Sand Körnung 0-2', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Kies Körnung 2-8', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Kies Körnung 8-16', menge: '1 m³', preis: 'Tagespreis'},
  {bezeichnung: 'Mindermengenzuschlag bis 0,3 m³', menge: '1 m³', preis: 'Tagespreis'},


];


@Component({
  selector: 'app-fertigbeton',
  templateUrl: './fertigbeton.component.html',
  styleUrls: ['./fertigbeton.component.css']
})
export class FertigbetonComponent {

  displayedColumnsbeton: string[] = ['bezeichnung', 'preis'];
  dataSource = ELEMENT_DATA;

}

