import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiefbau',
  templateUrl: './tiefbau.component.html',
  styleUrls: ['./tiefbau.component.css']
})
export class TiefbauComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
